import React, { Suspense } from "react";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/utilComponents/ScrollToTop";
import { Route, HashRouter, Routes } from "react-router-dom";
import Lagottos from "./components/Lagottos";
import { Spinner } from "react-bootstrap";
import { Auth0Provider } from "@auth0/auth0-react";

const Landing = React.lazy(() => import("./components/Landing"));
const About = React.lazy(() => import("./components/About"));
const Contact = React.lazy(() => import("./components/Contact"));
const Health = React.lazy(() => import("./components/Health"));
const Litters = React.lazy(() => import("./components/Litters"));
const Join = React.lazy(() => import("./components/Join"));
const Events = React.lazy(() => import("./components/Events"));
const Joinform = React.lazy(() => import("./components/Joinform"));
const Event = React.lazy(() => import("./components/Event"));
const AdminLogin = React.lazy(() => import("./components/admin/Login"));
const Dashboard = React.lazy(() => import("./components/admin/Dashboard"));


const App = () => {

      const supporters = 1;

      const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
      React.useEffect(() => {
        const handleWindowResize = () => {
          setWindowWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleWindowResize);
        return () => {
          window.removeEventListener("resize", handleWindowResize);
        };
      });

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: process.env.REACT_APP_REDIRECT_URI as string,
      }}
    >
      <HashRouter>
        <div className="App">
          <Header windowWidth={windowWidth} />
          <Suspense
            fallback={
              <Spinner
                animation="border"
                role="status"
                style={{ marginTop: "10rem" }}
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            }
          >
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Landing windowWidth={windowWidth} />} />
              <Route
                path="uhingust"
                element={
                  <About windowWidth={windowWidth} supporters={supporters} />
                }
              />
              <Route
                path="lagotto"
                element={<Lagottos windowWidth={windowWidth} />}
              />
              <Route
                path="tervis"
                element={<Health windowWidth={windowWidth} />}
              />
              <Route path="pesakonnad" element={<Litters />} />
              <Route path="events" element={<Events />} />
              <Route
                path="kontakt"
                element={<Contact windowWidth={windowWidth} />}
              />
              <Route
                path="liitu"
                element={
                  <Join windowWidth={windowWidth} supporters={supporters} />
                }
              />
              <Route
                path="liitumisevorm"
                element={
                  <Joinform windowWidth={windowWidth} supporters={supporters} />
                }
              />
              <Route
                path="events/event/*"
                element={<Event windowWidth={windowWidth} />}
              />
              <Route path="sitemap.xml" />
              <Route path="dogadmin/login" element={<AdminLogin />} />
              <Route path="dogadmin/dashboard" element={<Dashboard />} />
            </Routes>
          </Suspense>
          <Footer />
        </div>
      </HashRouter>
    </Auth0Provider>
  );
};

export default App;
